import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Modal,
  IconButton,
  CardActions,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  maxHeight: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const IncentiveStatusModal = ({ open, handleClose, contest }) => {
  if (!contest) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={modalStyle}>
        <CardHeader
          title="My 2025 Quiz Completion"
          onClick={handleClose}
          action={
            <IconButton>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Typography>
            Quizzes completed in 2025: {contest.quiz_completions}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={handleClose}>Close</Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default IncentiveStatusModal;
