import React, { useContext } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { context, actions } from "../AuthContext";

import LoginForm from ".";
import LoginHelp from "../LoginHelp";
import HelpButton from "../HelpButton";
import Quiz from "../PublicHome/Quiz";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
    maxWidth: "100% !important",
    padding: "0 !important",
  },
  loginContainer: {
    width: "28rem",
  },
  imageContainer: {
    maxHeight: 125,
    margin: theme.spacing(3),
    textAlign: "center",
  },
  logo: {
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: 125,
  },
  poweredBy: {
    color: theme.palette.grey[700],
    fontSize: "1.1em !important",
  },
}));

const Login = ({ config = {}, onCancel, ...rest }) => {
  const classes = useStyles();
  const { dispatch } = useContext(context);

  const {
    company = {},
    title,
    logo_caption: logoCaption,
    instructions,
    disclaimer,
    email,
    loginType,
    consolationQuiz,
  } = config;

  return (
    <Container id="container" className={classes.container}>
      <Card id="card" className={classes.loginContainer}>
        {company.logo && (
          <>
            <div className={classes.imageContainer}>
              <img
                src={company.logo}
                className={classes.logo}
                alt="Company Logo"
              />
            </div>
            {logoCaption && (
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                style={{ fontStyle: "italic" }}
              >
                {logoCaption}
              </Typography>
            )}
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              className={classes.poweredBy}
            >
              Powered by{" "}
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                color="primary"
                sx={{
                  fontWeight: "900 !important",
                  fontFamily: "Didot !important",
                  fontSize: "1.25em",
                }}
                component="span"
              >
                Quizzify
              </Typography>
            </Typography>
            <Divider />
          </>
        )}
        <CardHeader
          title={title || "Log In"}
          subheader={
            instructions ? (
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {instructions}
              </Typography>
            ) : null
          }
        />

        {consolationQuiz && (
          <>
            <Divider />
            <CardContent
              sx={(theme) => ({ backgroundColor: theme.palette.grey[200] })}
            >
              <Quiz
                quiz={consolationQuiz}
                flat
                onClick={() => {
                  dispatch({
                    type: actions.clearPromptLogin,
                  });
                }}
              />
            </CardContent>
          </>
        )}

        <LoginForm config={config} {...rest} />

        {disclaimer && (
          <div>
            <Divider />
            <CardContent>
              <Typography>{disclaimer}</Typography>
            </CardContent>
          </div>
        )}

        {(onCancel || email) && (
          <>
            <Divider />
            <CardActions spacing={2}>
              {onCancel && (
                <>
                  <Button
                    onClick={onCancel}
                    startIcon={<ArrowBackIcon />}
                    size="small"
                  >
                    Back
                  </Button>
                  <span style={{ width: 15 }} />
                </>
              )}
              {email && loginType === "emailpwLogin" ? (
                <LoginHelp
                  email={email}
                  contactButton={<HelpButton title="Contact Us" size="small" />}
                />
              ) : (
                <HelpButton title="Contact Us" size="small" />
              )}
            </CardActions>
          </>
        )}
      </Card>
    </Container>
  );
};

export default Login;
