import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(orderBy) {
  return (a, b) => descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headers, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.rightAlign ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? "desc" : false}
          >
            {headCell.noSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={"desc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    sorted descending
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function SortableTable({
  headers,
  rows,
  startingSort = "total_quizzes",
}) {
  console.log(startingSort);

  const [orderBy, setOrderBy] = React.useState(startingSort);

  const handleRequestSort = (event, property) => {
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(orderBy)),
    [orderBy, rows]
  );

  const currentUserRef = useRef(null);

  const setRef = (node) => {
    currentUserRef.current = node;

    if (currentUserRef.current) {
      setTimeout(() => {
        currentUserRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 250);
    }
  };

  return (
    <Table
      aria-labelledby="leaderboard-table"
      size="small"
      stickyHeader
      sx={{ overflow: "scroll" }}
    >
      <EnhancedTableHead
        headers={headers}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody sx={{ overflow: "scroll" }}>
        {visibleRows.map((row, index) => (
          <TableRow
            key={row.user.id}
            ref={row.currentUser ? setRef : null}
            sx={(theme) =>
              row.currentUser
                ? { backgroundColor: theme.palette.secondary.main }
                : {}
            }
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row.user.name}</TableCell>
            <TableCell align="right">{row.total_quizzes}</TableCell>
            <TableCell align="right">{row.total_games}</TableCell>
            <TableCell align="right">{Math.round(row.average_score)}</TableCell>
            <TableCell align="right">{Math.round(row.total_score)}</TableCell>
            <TableCell align="right">
              {Math.round(row.average_first_try_score)}
            </TableCell>
            <TableCell align="right">
              {Math.round(row.first_try_total_score)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
