import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Box,
  Stack,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ErConsentPass from "./ErConsentPass";

const RightColumn = ({ companyId, hideErConsentOnHomePage }) => {
  return (
    <Stack spacing={2}>
      <ErConsentPass
        companyId={companyId}
        hideErConsentOnHomePage={hideErConsentOnHomePage}
      />
      <Card variant="outlined">
        <CardHeader
          avatar={<ExitToAppIcon />}
          title={
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Prepare for Your Next Doctor Visit
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Stack spacing={3}>
            <Typography>
              Choose from over 200 "Doctor Visit PrepKits" to prepare for your
              next clinical visit.
            </Typography>

            <Box sx={{ pl: 5, pr: 5, textAlign: "center" }}>
              <img
                alt="Prevent Consent Preview"
                src="/img/prepkit-preview-2.png"
                style={{ maxWidth: "100%", maxHeight: 350 }}
              />
            </Box>

            <Button href={"/prepkits"} variant="contained" color="secondary">
              Get Started
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default RightColumn;
