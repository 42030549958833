import React from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const FeedbackIcon = ({ wasSelected, isCorrect, multipleChoice }) => {
  if (multipleChoice) {
    return wasSelected ? (
      isCorrect ? (
        <CheckBoxIcon
          sx={(theme) => ({
            color: theme.palette.common.green,
          })}
        />
      ) : (
        <DisabledByDefaultIcon
          sx={(theme) => ({
            color: theme.palette.error.dark,
          })}
        />
      )
    ) : (
      <CheckBoxOutlineBlankIcon />
    );
  } else {
    return wasSelected ? (
      isCorrect ? (
        <CheckCircleIcon
          sx={(theme) => ({
            color: theme.palette.common.green,
          })}
        />
      ) : (
        <CancelIcon
          sx={(theme) => ({
            color: theme.palette.error.dark,
          })}
        />
      )
    ) : (
      <RadioButtonUncheckedIcon />
    );
  }
};

export default FeedbackIcon;
