import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Stack,
  Alert,
  Divider,
} from "@mui/material";

import Quiz from "./Quiz";
import Tabs from "../Tabs";
import { Loader } from "../Loader";

const renderTabs = (quizzes, rest) => {
  const { id: companyId, quiz_groups } = rest;

  let eligibleLabel = "Eligible";
  let bonusLabel = "Bonus";

  if (companyId === 2851) {
    eligibleLabel = "Featured";
    bonusLabel = "Specialty";
  }

  if (quizzes.every((q) => q.group)) {
    const quizGroupsToUse = quiz_groups.filter((group) =>
      quizzes.some((q) => q.group.name === group.name)
    );

    return (
      <Tabs
        tabs={quizGroupsToUse.map((group) => {
          const isEligible = group.is_eligible;

          const component = isEligible ? (
            <Stack spacing={2}>
              <Alert severity="info">
                These are eligible for program incentives and rewards.
              </Alert>
              {quizzes
                ?.filter((q) => q.group.name === group.name)
                .map((q, i) => (
                  <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
                ))}
            </Stack>
          ) : (
            <Stack spacing={2}>
              {quizzes
                ?.filter((q) => q.group.name === group.name)
                .map((q, i) => (
                  <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
                ))}
            </Stack>
          );

          return {
            label: group.name,
            eligible: group.is_eligible,
            component,
          };
        })}
      />
    );
  }

  return (
    <Tabs
      tabs={[
        {
          label: eligibleLabel,
          component: (
            <Stack spacing={2}>
              {quizzes
                ?.filter((q) => q.eligible)
                .map((q, i) => (
                  <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
                ))}
            </Stack>
          ),
        },
        {
          label: bonusLabel,
          component: (
            <Stack spacing={2}>
              {quizzes
                ?.filter((q) => !q.eligible)
                .map((q) => (
                  <Quiz key={q.id} quiz={q} {...rest} />
                ))}
            </Stack>
          ),
        },
      ]}
    />
  );
};

const QuizzesPanel = ({ quizzes, showTabs, ...rest }) => {
  if (!quizzes)
    return (
      <CardContent>
        <Loader />
      </CardContent>
    );
  if (quizzes.length === 0)
    return (
      <CardContent>
        <Typography>
          No Quiz is Available Just Yet, But Please Hang On We'll Have Them for
          You Soon!
        </Typography>
      </CardContent>
    );

  return (
    <>
      {showTabs ? (
        renderTabs(quizzes, rest)
      ) : (
        <Stack spacing={2}>
          {quizzes.map((q, i) => (
            <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
          ))}
        </Stack>
      )}
    </>
  );
};

const Quizzes = ({ quizzes, ...rest }) => {
  const hasBonus = quizzes?.some((q) => !q.eligible);

  return (
    <Card>
      <CardHeader
        sx={{ backgroundColor: "white" }}
        title={
          <Stack spacing={1} direction="row" justifyContent="center">
            <Typography variant="h6">
              {!quizzes
                ? "We Are Getting Your Latest and Greatest Quizzes!"
                : "Your Quizzes"}
            </Typography>
          </Stack>
        }
      />
      <Divider />
      <CardContent>
        <QuizzesPanel quizzes={quizzes} showTabs={hasBonus} {...rest} />
      </CardContent>
    </Card>
  );
};

export default Quizzes;
